<template>
  <div class="worker dataM">
    <el-table 
      :data="tableList" 
      header-cell-class-name="tableHeader" 
      style="width: 100%" 
      @selection-change="handleSelectionChange"
      @row-dblclick="handleEdit"
    >
      <el-table-column type="selection" width="55"/>
      <el-table-column label="序号" type="index" width="100">
        <template slot-scope="scope">
          <span>{{((page-1)*10+scope.$index+1) < 10 ? `0${(page-1)*10+scope.$index+1}` : (page-1)*10+scope.$index+1}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="staffStatus" label="状态">
        <template slot="header">
          <el-dropdown>
            <span class="el-dropdown-link">
              状态<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="changeStatus(0)">全部</el-dropdown-item>
              <el-dropdown-item @click.native="changeStatus(1)">在职</el-dropdown-item>
              <el-dropdown-item @click.native="changeStatus(2)">离职</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template slot-scope="scope">
          <span v-if="scope.row.staffStatus == 1" class="greenStatus">在职</span>
          <span class="redStatus" v-else>离职</span>
        </template>
      </el-table-column>
      <el-table-column prop="staffNo" label="员工编号"/>
      <el-table-column prop="staffName" label="姓名"/>
      <el-table-column prop="staffDepartment" label="部门"/>
      <el-table-column prop="jobTitle" label="岗位"/>
      <el-table-column prop="skill" label="技能"/>
      <el-table-column prop="workshiftSchemeName" label="日历方案"/>
      <el-table-column label="操作" width="300">
        <template slot-scope="scope">
          <div class="operation">
            <el-tooltip effect="dark" content="编辑" placement="top">
              <img src="@/assets/images/dataManage/edit.png" style="margin-right:10px" @click="handleEdit(scope.row)"/>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除" placement="top">
              <img src="@/assets/images/dataManage/del.png"  @click="comfirm(scope.row)"/>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <workerDialog :createVisible.sync="createVisible" :currentData="currentData" @handleSearch="handleSearch"/>
  </div>
</template>

<script>
import workerDialog from '../dialog/workerDialog.vue'
export default {
  name:"worker",
  components:{
    workerDialog
  },
  props:{
    tableList:{
      require:true,
      default:[]
    },
    statusType:{
      require:true
    },
    page:{
      default:1
    }
  },
  data(){
    return{
      createVisible:false,
      currentData:{}
    }
  },
  methods:{
    handleSelectionChange(val){
      let multipleSelection = val.map(row => row.id);
      this.$emit('update:multipleSelection',multipleSelection);
    },
    comfirm(row){
      this.$emit('comfirm',row)
    },
    changeStatus(val){
      this.$emit('update:statusType',val)
    },
    handleEdit(row){
      this.currentData = row
      this.createVisible = true
    },
    handleSearch(){
      this.$emit('handleSearch')
    }
  }
}
</script>

<style lang="scss">
.worker{
  .operation{
    font-size: 16px;
    display: flex;
    align-items: center;
    span{
      display: inline-block;
      margin-left: 10px;
    }
  }
  img{
    margin: 0 15px;
    cursor: pointer;
    width: 22px;
    height: 22px;
  }
}
</style>